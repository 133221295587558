angular.module('MyHippoProducer.Services').factory('HeapService', function () {
    const heapKey = window.PRODUCER_HEAP_KEY;
    if (heapKey) {
    /*eslint-disable */
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load(heapKey);
    /* eslint-enable */
    }

    const identify = (item) => {
        if (window.heap) {
            window.heap.identify(item);
        }
    };

    const addUserProperties = (properties) => {
        if (window.heap) {
            window.heap.addUserProperties(properties);
        }
    };

    const resetIdentity = () => {
        if (window.heap) {
            window.heap.resetIdentity();
        }
    };

    const trackEvent = (eventName, properties) => {
        if (window.heap) {
            window.heap.track(eventName, properties);
        }
    };

    return { identify, addUserProperties, resetIdentity, trackEvent };
});